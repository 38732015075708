<template>
  <PageLoader />
  <Header />
  <Sidebar />

  <div class="content">
    <router-view></router-view>
  </div>

  <div v-if="isFadeVisible==true" class="fade show"></div>
</template>

<script>
import UserService from '@/services/user.service';
import messages from '@/lib/messages';
import PageLoader from '@/components/loaders/PageLoader';
import Header from '@/layouts/Header';
import Sidebar from '@/layouts/Sidebar';

export default {
  name: 'MainLayout',
  components: {
    PageLoader,
    Header,
    Sidebar,
  },

  computed: {
    notify() {
      return this.$store.getters.notify;
    },
    isFadeVisible() {
      return this.$store.getters.isFadeVisible;
    },
  },

  watch: {
    notify(obj) {
      this.$notify({ type: obj.type, text: messages[obj.message] || 'Что-то пошло не так' });
    },
    isFadeVisible(value) {
      if (value === true) document.body.classList.add('fade-show');
      else document.body.classList.remove('fade-show');
    }
  },

  async mounted() {
    await this.getData();
  },

  methods: {
    async getData() {
      try {
        let res = await UserService.getData();
        this.$store.dispatch('auth/refreshLeadcoin', res.data.leadcoin);
        this.$store.dispatch('auth/refreshCountTasks', res.data.countTasks);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
