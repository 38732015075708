<template>
  <div class="page-loader" v-show="pageLoader">
    <Spinner />
  </div>
</template>

<script>
import Spinner from '@/components/loaders/Spinner.vue';

export default {
  name: 'PageLoader',
  components: {
    Spinner,
  },

  computed: {
    pageLoader() {
      return this.$store.state.pageLoader;
    },
  },
};
</script>
