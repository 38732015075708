<template>
  <header class="header">
    <div class="navigation-toggler" :class="{'toggled':sidebarToggled}" @click="$store.dispatch('sidebarToggled');">
      <div class="navigation-toggler__inner"><i class="navigation-toggler__line"></i><i class="navigation-toggler__line"></i><i class="navigation-toggler__line"></i></div>
    </div>

    <div class="header__logo">
      <img class="header__logo-image" src="@/assets/images/logo.png">
    </div>

    <div v-can="['apiBirthday']" v-if="birthdayUsers.length" class="header__left">Сегодня день рождения: {{birthdayUsers}}</div>

    <div v-can="['apiCountLeads']" v-if="topUsers.length" class="header__right">
      Лидеры дня: <span v-if="topUsers[0]">{{topUsers[0].name}} ({{topUsers[0].countLeads}})</span>, <span v-if="topUsers[1]">{{topUsers[1].name}} ({{topUsers[1].countLeads}})</span>, <span v-if="topUsers[2]">{{topUsers[2].name}} ({{topUsers[2].countLeads}})</span>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',

  computed: {
    sidebarToggled() {
      return this.$store.getters.sidebarToggled;
    },
    birthdayUsers() {
      return this.$store.getters.birthdayUsers.map(item => item.name).join(', ');
    },
    topUsers() {
      return this.$store.getters.topUsers;
    },
  },

  methods: {
    async logout() {
      this.$store.dispatch('auth/logout')
        .then(() => this.$router.push({ name: 'auth/login' }));
    },
  },
};
</script>
