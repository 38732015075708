// Получение GET-параметров из адреса
export function getUriParams() {
  const vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = decodeURIComponent(value);
  });
  return vars;
}

// Генерация URL-параметров из объекта
export function httpBuildQuery(formdata, numericPrefix, argSeparator) {
  var key, useVal, useKey, i = 0, tmpArr = [];

  if (!argSeparator) {
    argSeparator = '&';
  }

  for (key in formdata) {
    useKey = escape(key);
    useVal = escape((formdata[key].toString()));
    useVal = useVal.replace(/%20/g, '+');

    if (numericPrefix && !isNaN(key)) {
      useKey = numericPrefix + i;
    }
    tmpArr[i] = useKey + '=' + useVal;
    i++;
  }

  return tmpArr.join(argSeparator);
}

// Перемешивание массива
export function shuffle(array) {
  for (let i = array.length-1; i > 0; i--) {
    let j = Math.floor(Math.random()*(i+1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

// Генерация случайного числа из диапазона
export function randInt(min, max) {
  let rand = min-0.5+Math.random()*(max-min+1);
  rand = Math.round(rand);
  return rand;
}
