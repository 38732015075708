<template>
  <div class="sidebar" :class="{'toggled':sidebarToggled}">
    <Menu v-if="currentUser">
      <div class="dropdown" style="top:0">
        <MenuButton class="user__info" as="div">
          <img class="user__img" src="@/assets/images/avatar-default.png">
          <div class="user__desc">
            <div class="user__name">{{currentUser.username}}</div>
            <div class="user__leadcoin">Лидкоины: {{currentUser.leadcoin}}</div>
          </div>
        </MenuButton>
        <MenuItems class="dropdown-menu show">
          <MenuItem class="dropdown-item"><router-link :to="'/user/'+currentUser.username+'/profile'">Профиль</router-link></MenuItem>
          <MenuItem class="dropdown-item"><a href="javascript:void(0)" @click="logout()">Выход</a></MenuItem>
        </MenuItems>
      </div>
    </Menu>

    <div class="navigation">
      <template v-for="(item1, index1) in menu">
        <div v-if="item1.allow" class="navigation__item" :class="{'navigation__item_open':item1.open,'navigation__item_active':typeof item1.url!='undefined'&&$route.path==item1.url}" :key="index1">
          <router-link v-if="item1.type=='link'" class="navigation__item-link" :class="{'navigation__item-link_marker':item1.countTasks&&currentUser.countTasks&&currentUser.countTasks>0}" :to="item1.url" ><i class="zmdi" :class="item1.icon"></i>{{item1.title}}</router-link>
          <span v-else-if="item1.type=='external-link'&&item1.copy" class="navigation__item-link" @click="copyLink(item1.url)" title="Скопировать"><i class="zmdi" :class="item1.icon"></i>{{item1.title}}</span>
          <a v-else-if="item1.type=='external-link'&&!item1.copy" class="navigation__item-link" :href="item1.url" target="_blank"><i class="zmdi" :class="item1.icon"></i>{{item1.title}}</a>
          <template v-if="item1.items">
            <div class="navigation__item-link" @click="toggleSubmenu(index1)"><i class="zmdi" :class="item1.icon"></i>{{item1.title}}</div>
            <div class="navigation__sub">
              <template v-for="(item2, index2) in item1.items">
                <div v-if="item2.allow&&item2.type=='link'" class="navigation__item" :class="{'navigation__item_active':typeof item2.url!='undefined'&&$route.path==item2.url}" :key="index2">
                  <router-link class="navigation__item-link" :to="item2.url">{{item2.title}}</router-link>
                </div>
                <div v-if="item2.allow&&item2.type=='section'" class="navigation__section" :key="index2">
                  <template v-for="(item3, index3) in item2.items">
                    <div v-if="item3.allow" class="navigation__item" :class="{'navigation__item_active':typeof item3.url!='undefined'&&$route.path==item3.url}" :key="index3">
                      <router-link v-if="item3.type=='link'" class="navigation__item-link" :to="item3.url">{{item3.title}}</router-link>
                      <div v-if="item3.type=='title'" class="navigation__item-title">{{item3.title}}</div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
} from '@headlessui/vue';
import SettingService from '@/services/setting.service';

export default {
  name: 'Sidebar',
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },

  props: ['value'],

  data() {
    return {
      menu: [
        { type: 'link', title: 'Недвижимость', icon: 'zmdi-cloud', url: '/real-estate-lead', allow: this.$can(['apiRealEstateLeadList','apiRealEstateLeadListCustomer','apiRealEstateLeadListDepartment','apiRealEstateLeadListOwner']) },
        { type: 'link', title: 'Авто', icon: 'zmdi-cloud', url: '/auto-lead', allow: this.$can(['apiAutoLeadList','apiAutoLeadListCustomer','apiAutoLeadListDepartment','apiAutoLeadListOwner']) },
        { type: 'link', title: 'Партнер', icon: 'zmdi-cloud', url: '/partner-lead', allow: this.$can(['apiPartnerLeadList','apiPartnerLeadListCustomer','apiPartnerLeadListDepartment','apiPartnerLeadListOwner']) },
        { type: 'link', title: 'HR отдел', icon: 'zmdi-cloud', url: '/hr-staff', allow: this.$can(['apiHrStaffList','apiHrStaffListOwner']) },
        { type: 'link', title: 'HR', icon: 'zmdi-cloud', url: '/hr-staff2', allow: this.$can(['apiHrStaff2List','apiHrStaff2ListOwner']) },
        { type: 'link', title: 'Перезвон Недвижимость', icon: 'zmdi-cloud', url: '/callback-lead', allow: this.$can(['apiCallbackLeadList','apiCallbackLeadListOwner']) },
        { type: 'link', title: 'Перезвон Авто', icon: 'zmdi-cloud', url: '/auto-callback-lead', allow: this.$can(['apiAutoCallbackLeadList','apiAutoCallbackLeadListOwner']) },
        { type: 'link', title: 'Объемы Недвижимость', icon: 'zmdi-cloud', url: '/real-estate-volume', allow: this.$can(['apiRealEstateVolumeList']) },
        { type: 'link', title: 'Объемы Авто', icon: 'zmdi-cloud', url: '/auto-volume', allow: this.$can(['apiAutoVolumeList']) },
        { type: 'link', title: 'Базы', icon: 'zmdi-cloud', url: '/base', allow: this.$can(['apiBaseList']) },
        { type: 'link', title: 'Циан', icon: 'zmdi-cloud', url: '/cian/newbuilding', allow: this.$can(['apiCian']) },
        { type: 'submenu', title: 'Нормативные документы', icon: 'zmdi-file-text', allow: false, items: [
          { type: 'link', title: 'Недвижимость', url: '/regulation/real-estate', allow: this.$can(['apiRealEstateRegulationList']) },
          { type: 'link', title: 'Авто', url: '/regulation/auto', allow: this.$can(['apiAutoRegulationList']) },
        ] },
        { type: 'submenu', title: 'Справочники', icon: 'zmdi-format-list-bulleted', allow: this.$can(['apiRealEstateCustomerList','apiRealEstateResidentialComplexList','apiRealEstateBuilderList','apiAutoCustomerList','apiAutoProjectList']), items: [
          { type: 'section', title: 'Недвижимость', allow: this.$can(['apiRealEstateCustomerList','apiRealEstateResidentialComplexList','apiRealEstateBuilderList']), items: [
            { type: 'title', title: 'Недвижимость', allow: true },
            { type: 'link', title: 'Заказчики', url: '/real-estate-customer', allow: this.$can(['apiRealEstateCustomerList']) },
            { type: 'link', title: 'Жилые комплексы', url: '/real-estate-residential-complex', allow: this.$can(['apiRealEstateResidentialComplexList']) },
            { type: 'link', title: 'Классы ЖК', url: '/real-estate-residential-complex-class', allow: this.$can(['apiRealEstateResidentialComplexClassList']) },
            { type: 'link', title: 'Регионы ЖК', url: '/real-estate-residential-complex-region', allow: this.$can(['apiRealEstateResidentialComplexRegionList']) },
            { type: 'link', title: 'Застройщики', url: '/real-estate-builder', allow: this.$can(['apiRealEstateBuilderList']) },
            { type: 'link', title: 'Объекты', url: '/real-estate-object', allow: this.$can(['apiRealEstateObjectList']) },
            { type: 'link', title: 'Кнопки объектов', url: '/real-estate-object-button', allow: this.$can(['apiRealEstateObjectList']) },
            { type: 'link', title: 'Нормативные документы', url: '/real-estate-regulation', allow: this.$can(['apiRealEstateRegulationList']) },
            { type: 'link', title: 'Откуда лид', url: '/real-estate-where-lead', allow: this.$can(['apiRealEstateWhereLeadList']) },
            { type: 'link', title: 'Статусы РК', url: '/real-estate-status-rk', allow: this.$can(['apiRealEstateStatusRkList']) },
            { type: 'link', title: 'Классы', url: '/real-estate-class', allow: this.$can(['apiRealEstateClassList']) },
            //{ type: 'link', title: 'Коэффициенты РК', url: '/real-estate-koeff-rk', allow: this.$can(['apiRealEstateKoeffRkList']) },
          ] },
          { type: 'section', title: 'Авто', allow: this.$can(['apiAutoCustomerList','apiAutoProjectList']), items: [
            { type: 'title', title: 'Авто', allow: true },
            { type: 'link', title: 'Заказчики', url: '/auto-customer', allow: this.$can(['apiAutoCustomerList']) },
            { type: 'link', title: 'Проекты', url: '/auto-project', allow: this.$can(['apiAutoProjectList']) },
            { type: 'link', title: 'Регионы', url: '/auto-project-region', allow: this.$can(['apiAutoProjectRegionList']) },
            { type: 'link', title: 'Объекты', url: '/auto-object', allow: this.$can(['apiAutoObjectList']) },
            { type: 'link', title: 'Кнопки объектов', url: '/auto-object-button', allow: this.$can(['apiAutoObjectList']) },
            { type: 'link', title: 'Нормативные документы', url: '/auto-regulation', allow: this.$can(['apiAutoRegulationList']) },
            { type: 'link', title: 'Откуда лид', url: '/auto-where-lead', allow: this.$can(['apiAutoWhereLeadList']) },
            { type: 'link', title: 'Статусы РК', url: '/auto-status-rk', allow: this.$can(['apiAutoStatusRkList']) },
            { type: 'link', title: 'Классы', url: '/auto-class', allow: this.$can(['apiAutoClassList']) },
            //{ type: 'link', title: 'Коэффициенты РК', url: '/auto-koeff-rk', allow: this.$can(['apiAutoKoeffRkList']) },
          ] },
          { type: 'section', title: 'Партнер', allow: this.$can(['apiPartnerCustomerList','apiPartnerResidentialComplexList','apiPartnerBuilderList']), items: [
            { type: 'title', title: 'Партнер', allow: true },
            { type: 'link', title: 'Заказчики', url: '/partner-customer', allow: this.$can(['apiPartnerCustomerList']) },
            { type: 'link', title: 'Жилые комплексы', url: '/partner-residential-complex', allow: this.$can(['apiPartnerResidentialComplexList']) },
            { type: 'link', title: 'Классы ЖК', url: '/partner-residential-complex-class', allow: this.$can(['apiPartnerResidentialComplexClassList']) },
            { type: 'link', title: 'Застройщики', url: '/partner-builder', allow: this.$can(['apiPartnerBuilderList']) },
            { type: 'link', title: 'Объекты', url: '/partner-object', allow: this.$can(['apiPartnerObjectList']) },
            { type: 'link', title: 'Кнопки объектов', url: '/partner-object-button', allow: this.$can(['apiPartnerObjectList']) },
            { type: 'link', title: 'Нормативные документы', url: '/partner-regulation', allow: this.$can(['apiPartnerRegulationList']) },
            { type: 'link', title: 'Откуда лид', url: '/partner-where-lead', allow: this.$can(['apiPartnerWhereLeadList']) },
            { type: 'link', title: 'Статусы РК', url: '/partner-status-rk', allow: this.$can(['apiPartnerStatusRkList']) },
            //{ type: 'link', title: 'Коэффициенты РК', url: '/partner-koeff-rk', allow: this.$can(['apiPartnerKoeffRkList']) },
          ] },
          { type: 'section', title: 'HR отдел', allow: this.$can(['apiHrAdvertChannelList','apiHrSupervisorList','apiHrShiftList','apiHrSignedUpInterviewList','apiHrPassedAdaptationList','apiHrStatusList','apiHrStatusTrainingList','apiHrStatusTotalList']), items: [
            { type: 'title', title: 'HR отдел', allow: true },
            { type: 'link', title: 'Рекламный канал', url: '/hr-advert-channel', allow: this.$can(['apiHrAdvertChannelList']) },
            { type: 'link', title: 'Руководители', url: '/hr-supervisor', allow: this.$can(['apiHrSupervisorList']) },
            { type: 'link', title: 'Смены', url: '/hr-shift', allow: this.$can(['apiHrShiftList']) },
            { type: 'link', title: 'Записался на собеседование', url: '/hr-signed-up-interview', allow: this.$can(['apiHrSignedUpInterviewList']) },
            { type: 'link', title: 'Статусы адаптации', url: '/hr-passed-adaptation', allow: this.$can(['apiHrPassedAdaptationList']) },
            { type: 'link', title: 'Статусы HR', url: '/hr-status', allow: this.$can(['apiHrStatusList']) },
            { type: 'link', title: 'Статусы обучения', url: '/hr-status-training', allow: this.$can(['apiHrStatusTrainingList']) },
            { type: 'link', title: 'Итоговые статусы', url: '/hr-status-total', allow: this.$can(['apiHrStatusTotalList']) },
          ] },
          { type: 'section', title: 'Перезвон Недвижимость', allow: this.$can(['apiCallbackBudgetList','apiCallbackDirectionList','apiCallbackLocationList','apiCallbackQuantityRoomsList','apiCallbackDecorationList','apiCallbackTypeList','apiCallbackDeadlineList']), items: [
            { type: 'title', title: 'Перезвон Недвижимость', allow: true },
            { type: 'link', title: 'Бюджет', url: '/callback-budget', allow: this.$can(['apiCallbackBudgetList']) },
            { type: 'link', title: 'Направление', url: '/callback-direction', allow: this.$can(['apiCallbackDirectionList']) },
            { type: 'link', title: 'Локация', url: '/callback-location', allow: this.$can(['apiCallbackLocationList']) },
            { type: 'link', title: 'Количество комнат', url: '/callback-quantity-rooms', allow: this.$can(['apiCallbackQuantityRoomsList']) },
            { type: 'link', title: 'Отделка', url: '/callback-decoration', allow: this.$can(['apiCallbackDecorationList']) },
            { type: 'link', title: 'Тип', url: '/callback-type', allow: this.$can(['apiCallbackTypeList']) },
            { type: 'link', title: 'Срок сдачи', url: '/callback-deadline', allow: this.$can(['apiCallbackDeadlineList']) },
          ] },
          { type: 'section', title: 'Перезвон Авто', allow: this.$can(['apiAutoCallbackBudgetList','apiAutoCallbackLocationList','apiAutoCallbackTypeList']), items: [
            { type: 'title', title: 'Перезвон Авто', allow: true },
            { type: 'link', title: 'Бюджет', url: '/auto-callback-budget', allow: this.$can(['apiAutoCallbackBudgetList']) },
            { type: 'link', title: 'Локация', url: '/auto-callback-location', allow: this.$can(['apiAutoCallbackLocationList']) },
            { type: 'link', title: 'Тип', url: '/auto-callback-type', allow: this.$can(['apiAutoCallbackTypeList']) },
          ] },
          { type: 'link', title: 'Статусы', url: '/status', allow: this.$can(['apiStatusList']) },
          { type: 'link', title: 'Статусы заказчиков', url: '/customer-status', allow: this.$can(['apiCustomerStatusList']) },
          { type: 'link', title: 'Статусы внутренние', url: '/internal-status', allow: this.$can(['apiInternalStatusList']) },
          { type: 'link', title: 'Статусы аудиторов 1', url: '/auditor-status', allow: this.$can(['apiAuditorStatusList']) },
          { type: 'link', title: 'Статусы аудиторов 2', url: '/auditor-status2', allow: this.$can(['apiAuditorStatus2List']) },
          { type: 'link', title: 'Новичок', url: '/newbie', allow: this.$can(['apiNewbie']) },
        ] },
        { type: 'link', title: 'Отделы', icon: 'zmdi-group-work', url: '/department', allow: this.$can(['apiDepartmentList']) },
        { type: 'link', title: 'Роли', icon: 'zmdi-accounts', url: '/role', allow: this.$can(['apiRoleList']) },
        { type: 'link', title: 'Пользователи', icon: 'zmdi-accounts', url: '/user', allow: this.$can(['apiUserList']) },
        { type: 'external-link', title: 'База знаний', icon: 'zmdi-link', url: '', allow: this.$can(['apiMap']) },
        { type: 'external-link', title: 'Реферальная ссылка', icon: 'zmdi-link', url: '', allow: this.$can(['apiReferal']), copy: true },
        //{ type: 'external-link', title: 'Обучение', icon: 'zmdi-link', url: '', allow: false },
        { type: 'link', title: 'Товары', icon: 'zmdi-shopping-cart', url: '/product', allow: this.$can(['apiProductList']) },
        { type: 'link', title: 'Магазин', icon: 'zmdi-shopping-cart', url: '/shop', allow: this.$can(['apiShop']) },
        { type: 'link', title: 'Задачи', icon: 'zmdi-comment-list', url: '/task', allow: this.$can(['apiTaskList','apiTaskListOwner','apiTaskTake']), countTasks: true },
        { type: 'link', title: 'Настройки', icon: 'zmdi-settings', url: '/settings', allow: this.$can(['apiSettingsList']), countTasks: true },
      ],

      userDropdownShowed: false,
    }
  },

  async mounted() {
    await this.getData();
    this.menu[16].url = this.setting.map.url;
    this.menu[17].url = this.referalUrl;
    //this.menu[14].url = this.setting.education.url;
    this.menu[11].allow = this.canRegulation();
    //this.menu[12].allow = this.canEducation();
  },

  computed: {
    sidebarToggled() {
      return this.$store.getters.sidebarToggled;
    },

    currentUser() {
      return this.$store.state.auth.user;
    },

    setting() {
      return this.$store.state.setting;
    },

    referalUrl() {
      /*let url = this.$store.state.setting.referal.url;
      url += '?name='+this.currentUser.firstname;
      url += '&surname='+this.currentUser.lastname;
      url += '&tel='+this.currentUser.phone;*/
      return this.currentUser.referalUrl;
    },
  },

  methods: {
    async getData() {
      try {
        const res = await SettingService.getAll();

        if (res.data && res.data.length) {
          this.$store.commit('setSetting', res.data);
        }
      } catch (err) {
        console.log(err);
      }
    },

    hideUserDropdown() {
      this.userDropdownShowed = false;
    },

    logout() {
      this.$store.dispatch('auth/logout')
        .then(() => this.$router.push({ name: 'login' }));
    },

    // Открытие/закрытие подменю
    toggleSubmenu(index) {
      this.menu[index].open = !this.menu[index].open;
    },

    canRegulation() {
      if (this.$can(['apiRealEstateRegulationList','apiPartnerRegulationList','apiAutoRegulationList']) !== true) {
        return false;
      }
      if (this.currentUser.isNewbie === true && this.setting.newbie.regulation !== true) {
        return false;
      }
      return true;
    },

    /*canEducation() {
      if (this.$can(['apiEducation']) !== true) {
        return false;
      }
      if (this.currentUser.isNewbie === true && this.setting.newbie.education !== true) {
        return false;
      }
      return true;
    },*/

    // Копирование реферальной ссылки
    copyLink(text) {
      try {
        navigator.clipboard.writeText(text);
        this.$store.dispatch('setNotify', { type: 'success', message: 'link-copied' });
      } catch (err) {
        console.log(err);
        console.log(err);
      }
    },
  },
};
</script>
