import api from './api';

class birthdayUsersService {
  getAll() {
    return api
      .get('/birthday-users')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
}

export default new birthdayUsersService();
