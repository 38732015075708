import { createStore } from 'vuex';
import { getUriParams } from '@/lib/functions';
import auth from './auth.module';

const params = getUriParams();

const store = createStore({
  state() {
    return {
      console: (process.env.VUE_APP_CONSOLE == 1 || (params.console && params.console == 1)) ? true : false,
      isTest: (process.env.VUE_APP_TEST == 1 || (params.test && params.test == 1)) ? true : false,
      notify: null,
      pageLoader: false,
      sidebarToggled: false,
      isFadeVisible: false,
      dadata: {},
      setting: {
        map: {
          url: '',
        },
        /*referal: {
          url: '',
        },*/
        /*education: {
          url: '',
        },*/
        newbie: {
          regulation: false,
          education: false,
        },
      },
      topUsers: [],
      birthdayUsers: [],
    };
  },

  mutations: {
    setNotify(state, value) { state.notify = value },
    clearNotify(state) { state.notify = null },

    showPageLoader(state) { state.pageLoader = true },
    hidePageLoader(state) { state.pageLoader = false },

    sidebarToggled(state) { state.sidebarToggled = !state.sidebarToggled },
    sidebarHide(state) { state.sidebarToggled = false },

    isFadeVisible(state, value) { state.isFadeVisible = value ? true : false },

    setSetting(state, obj) {
      obj.forEach(option => {
        if (option.name == 'map.url') state.setting.map.url = option.value;
        //else if (option.name == 'referal.url') state.setting.referal.url = option.value;
        //else if (option.name == 'education.url') state.setting.education.url = option.value;
        else if (option.name == 'newbie.regulation') state.setting.newbie.regulation = option.value;
        else if (option.name == 'newbie.education') state.setting.newbie.education = option.value;
      });
    },

    setTopUsers(state, arr) { state.topUsers = arr },
    setBirthdayUsers(state, arr) { state.birthdayUsers = arr },
  },

  actions: {
    setNotify({ commit }, data) {
      if (typeof data.message === 'string') {
        commit('setNotify', { type: data.type, message: data.message } );
      } else if (typeof data.message === 'object') {
        data.message.forEach(msg => {
          commit('setNotify', { type: data.type, message: msg })
        });
      } else {
        commit('setNotify', { type: data.type, message: '' } );
      }
    },

    sidebarToggled({ commit }) {
      commit('sidebarToggled');
    },

    isFadeVisible({ commit }, value) {
      commit('isFadeVisible', value);
    },

    initPage({ commit }) {
      commit('showPageLoader');
      commit('sidebarHide');
    },

    setTopUsers({ commit }, value) {
      commit('setTopUsers', value);
    },

    setBirthdayUsers({ commit }, value) {
      commit('setBirthdayUsers', value);
    },
  },

  getters: {
    notify: s => s.notify,
    sidebarToggled: s => s.sidebarToggled,
    isFadeVisible: s => s.isFadeVisible,
    topUsers: s => s.topUsers,
    birthdayUsers: s => s.birthdayUsers,
  },

  modules: {
    auth,
  },
});

export default store;
3