import api from './api';

class topUsersService {
  getAll() {
    return api
      .get('/top-users')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
}

export default new topUsersService();
