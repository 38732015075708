import api from './api';
import { httpBuildQuery } from '@/lib/functions';

class SettingService {
  getAll(data) {
    const params = data ? '?'+httpBuildQuery(data) : '';

    return api
      .get('/setting'+params)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  get(id) {
    return api
      .get('/setting/'+id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  update(data) {
    return api
      .post('/setting/update', data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
}

export default new SettingService();
