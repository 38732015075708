import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export default {
  namespaced: true,

  state: initialState,

  actions: {
    /*register({ dispatch }, data) {
      return AuthService.register(data).then(
        (res) => {
          dispatch('setNotify', { type: 'success', message: res.messages }, { root: true });
          return Promise.resolve(res.data);
        },
        (err) => {
          dispatch('setNotify', { type: 'error', message: err.messages }, { root: true });
          return Promise.reject(err);
        }
      );
    },*/

    login({ commit, dispatch }, data) {
      return AuthService.login(data).then(
        (res) => {
          commit('loginSuccess', res.data);
          return Promise.resolve(res);
        },
        (err) => {
          commit('loginFailed');
          dispatch('setNotify', { type: 'error', message: err.messages }, { root: true });
          return Promise.reject(err);
        }
      );
    },

    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },

    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken);
    },

    refreshLeadcoin({ commit }, leadcoin) {
      commit('refreshLeadcoin', leadcoin);
    },

    refreshCountTasks({ commit }, countTasks) {
      commit('refreshCountTasks', countTasks);
    },

    verifyEmail({ commit, dispatch }, data) {
      return AuthService.verifyEmail(data).then(
        (res) => {
          commit('loginSuccess', res.data);
          return Promise.resolve(res);
        },
        (err) => {
          commit('loginFailed');
          dispatch('setNotify', { type: 'error', message: err.messages }, { root: true });
          return Promise.reject(err);
        }
      );
    },

    requestPasswordReset({ dispatch }, data) {
      return AuthService.requestPasswordReset(data).then(
        (res) => {
          dispatch('setNotify', { type: 'success', message: res.messages }, { root: true });
          return Promise.resolve(res);
        },
        (err) => {
          dispatch('setNotify', { type: 'error', message: err.messages }, { root: true });
          return Promise.reject(err);
        }
      );
    },

    passwordReset({ dispatch }, data) {
      return AuthService.passwordReset(data).then(
        (res) => {
          dispatch('setNotify', { type: 'success', message: res.messages }, { root: true });
          return Promise.resolve(res);
        },
        (err) => {
          dispatch('setNotify', { type: 'error', message: err.messages }, { root: true });
          return Promise.reject(err);
        }
      );
    },
  },

  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailed(state) {
      state.status.loggedIn = false;
      state.user = null;
    },

    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },

    refreshToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, accessToken: accessToken };
    },

    refreshLeadcoin(state, leadcoin) {
      state.status.loggedIn = true;
      state.user = { ...state.user, leadcoin: leadcoin };
    },

    refreshCountTasks(state, countTasks) {
      state.status.loggedIn = true;
      state.user = { ...state.user, countTasks: countTasks };
    },
  },
};
