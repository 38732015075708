class TokenService {
  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.accessToken;
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem('user'));
    user.accessToken = token;
    localStorage.setItem('user', JSON.stringify(user));
  }

  updateLocalCountTasks(countTasks) {
    let user = JSON.parse(localStorage.getItem('user'));
    user.countTasks = countTasks;
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem('user');
  }
}

export default new TokenService();
